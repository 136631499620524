import { ButtonProps as MuiButtonProps, Typography } from '@mui/material';
import React from 'react';
import { StyledButton } from './styles';

interface ButtonProps extends MuiButtonProps {
  label?: string;
  loading?: boolean;
  onClick: (values: any) => void;
}

export const Button = (props: ButtonProps) => {
  const { label = '', loading = false, ...rest } = props;
  return (
    <StyledButton {...rest} loading={loading}>
      <Typography
        sx={{
          fontSize: '12px',
        }}
        noWrap
      >
        {label}
      </Typography>
    </StyledButton>
  );
};
