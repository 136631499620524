/* eslint-disable no-invalid-this */
import * as Yup from 'yup';

export const createSchema = Yup.object({
  name: Yup.string()
    .required('O nome é obrigatório')
    .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Insira seu nome e sobrenome.'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('O email é obrigatório'),
  profileId: Yup.string().required('Selecione um perfil'),
  areas: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        value: Yup.string(),
      }),
    )
    .min(1, 'Selecione pelo menos uma área'),
  login: Yup.string().required('O login é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
  passwordConfirmation: Yup.string()
    .required('Confirme a senha')
    .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais'),
});

export const updateSchema = Yup.object({
  name: Yup.string()
    .required('O nome é obrigatório')
    .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Insira seu nome e sobrenome.'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('O email é obrigatório'),
  profileId: Yup.string().required('Selecione um perfil'),
  areas: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        value: Yup.string(),
      }),
    )
    .min(1, 'Selecione pelo menos uma área'),
  login: Yup.string().required('O login é obrigatório'),
});

export const lunchTimeSchema = Yup.object({
  startTime: Yup.string().required('Selecione um horário inicial'),
  endTime: Yup.string().required('Selecione um horário final'),
});
