import { useWorker } from '@koale/useworker';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import { PaletteColor, TypeText, Typography } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { PayloadAction } from '@reduxjs/toolkit';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, SplitButton, StackedBar } from '../../../components';
import {
  useDispatch,
  useSelector,
  fetchNotificationsExportExcel,
  fetchNotificationsExportHTML,
  notificationsListSlice,
  fetchNotificationsExportFinesHTML,
  fetchNotificationsExportReceiptHTML,
  notificationSlice,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleMoney } from '../../../utils/Helpers';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { StyledTableHeader } from '../styles';
import {
  exportNotificationsToCSV,
  exportNotificationsToHTML,
  exportNotificationsFinesToHTML,
  handleApiDataNotificationsToCSV,
  handleApiDataNotificationsToHTML,
  handleApiDataNotificationsFinesToHTML,
  handleApiDataNotificationsReceiptToHTML,
  exportNotificationsReceiptToHTML,
} from './exports';

export const NotificationsIssuedTableToolbar = (props: {
  selectedRows: GridSelectionModel;
}) => {
  const dispatch = useDispatch();
  const {
    notificationsTotals,
    notificationsTotalsLoading,
    notificationsTotalsSum,
    notificationsExportHTMLLoading,
    notificationsExportExcelLoading,
    notificationsExportFinesHTMLLoading,
    notificationsExportReceiptHTMLLoading,
  } = useSelector((state) => state.notificationsListReducer);
  const notificationsFiltersValues = useSelector(
    (state) => state.notificationsFiltersValuesReducer,
  );
  const responsiveMode = useResponsiveMode();
  const [exportNotificationsToCSVWorker] = useWorker(
    handleApiDataNotificationsToCSV,
  );
  const [exportNotificationsToHTMLWorker] = useWorker(
    handleApiDataNotificationsToHTML,
  );
  const [exportNotificationsFinesToHTMLWorker] = useWorker(
    handleApiDataNotificationsFinesToHTML,
  );
  const [exportNotificationsReceiptToHTMLWorker] = useWorker(
    handleApiDataNotificationsReceiptToHTML,
  );
  const [irregularsTotalPrice, setIrregularsTotalPrice] = useState('0.00');

  const handleExportExcel = () => {
    dispatch(fetchNotificationsExportExcel(notificationsFiltersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload && res.payload.data) {
          exportNotificationsToCSV(
            res.payload.data,
            dispatch,
            exportNotificationsToCSVWorker,
          ).then(() => {
            dispatch(notificationsListSlice.actions.closeExportModal());
          });
        } else dispatch(notificationsListSlice.actions.closeExportModal());
      },
    );
  };

  const handleExportHTML = () => {
    dispatch(fetchNotificationsExportHTML(notificationsFiltersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload && res.payload.data) {
          exportNotificationsToHTML(
            res.payload.data,
            dispatch,
            exportNotificationsToHTMLWorker,
          ).then(() => {
            dispatch(notificationsListSlice.actions.closeExportModal());
          });
        } else dispatch(notificationsListSlice.actions.closeExportModal());
      },
    );
  };

  const handleExportReceiptHTML = () => {
    dispatch(
      fetchNotificationsExportReceiptHTML(notificationsFiltersValues),
    ).then((res: PayloadAction<any>) => {
      if (res.payload.data) {
        exportNotificationsReceiptToHTML(
          res.payload.data,
          dispatch,
          exportNotificationsReceiptToHTMLWorker,
        ).then(() => {
          dispatch(notificationsListSlice.actions.closeExportModal());
        });
      } else dispatch(notificationsListSlice.actions.closeExportModal());
    });
  };

  const handleExportFinesHTML = () => {
    dispatch(
      fetchNotificationsExportFinesHTML(notificationsFiltersValues),
    ).then((res: PayloadAction<any>) => {
      if (res.payload && res.payload.data) {
        exportNotificationsFinesToHTML(
          res.payload.data,
          dispatch,
          exportNotificationsFinesToHTMLWorker,
        ).then(() => {
          dispatch(notificationsListSlice.actions.closeExportModal());
        });
      } else dispatch(notificationsListSlice.actions.closeExportModal());
    });
  };

  const handleStackedBarTotals = () => {
    const totals: {
      value: number;
      description: string;
      color: TypeText | PaletteColor;
    }[] = [];

    if (notificationsTotals) {
      let cancelled = 0;
      let irregulars = 0;
      let regulars = 0;

      if (notificationsTotals.length > 0) {
        map(notificationsTotals, (item) => {
          item.situationId === 'C'
            ? (cancelled = item.count)
            : item.situationId === 'I'
            ? (irregulars = item.count)
            : (regulars = regulars + item.count);
        });

        regulars > 0 &&
          totals.push({
            value: regulars,
            description: `Regulares: ${regulars}`,
            color: palette.success,
          });
        irregulars > 0 &&
          totals.push({
            value: irregulars,
            description: `Irregulares: ${irregulars}`,
            color: palette.error,
          });
        cancelled > 0 &&
          totals.push({
            value: cancelled,
            description: `Canceladas: ${cancelled}`,
            color: palette.text,
          });
      }
    }
    return totals;
  };

  const handleCancel = () => {
    if (props.selectedRows.length > 0) {
      dispatch(notificationSlice.actions.toggleNotificationCancelListIsOpen());
    }
  };

  useEffect(() => {
    if (notificationsTotals && notificationsTotals.length > 0) {
      map(notificationsTotals, (item) => {
        if (item.situationId === 'I' && item.price) {
          setIrregularsTotalPrice(item.price);
        }
      });
    }
  }, [notificationsTotals]);

  return (
    <StyledTableHeader>
      {notificationsTotals && (
        <StackedBar
          loading={notificationsTotalsLoading}
          total={notificationsTotalsSum}
          data={handleStackedBarTotals()}
        />
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {responsiveMode !== 'phone' && responsiveMode !== 'tablet' && (
            <>
              <SplitButton
                label='Exportar em HTML'
                startIcon={<DownloadIcon />}
                loading={
                  notificationsExportHTMLLoading ||
                  notificationsExportFinesHTMLLoading ||
                  notificationsExportReceiptHTMLLoading
                }
                options={[
                  {
                    label: 'Relatório de notificações',
                    onClick: handleExportHTML,
                  },
                  {
                    label: 'Relatório de notificações para multa (diário)',
                    onClick: handleExportFinesHTML,
                  },
                  {
                    label: 'Comprovante de irregularidades',
                    onClick: handleExportReceiptHTML,
                  },
                ]}
              />
              <Button
                variant='contained'
                label='Relatório de notificações (excel)'
                startIcon={<DownloadIcon />}
                onClick={handleExportExcel}
                loading={notificationsExportExcelLoading}
              />
            </>
          )}
          <Button
            variant='contained'
            label='Cancelar notificações'
            startIcon={<CancelIcon />}
            onClick={handleCancel}
            disabled={props.selectedRows.length === 0}
            // loading={notificationsCancelLoading}
          />
        </div>
        {responsiveMode !== 'phone' && responsiveMode !== 'tablet' && (
          <Typography
            margin={1}
            sx={{
              textAlign: 'right',
              paddingRight: 2,
              fontSize: responsiveMode === 'small-screen' ? '12px' : '14px',
            }}
          >
            Qtd.: {notificationsTotalsSum}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Total: {handleMoney(irregularsTotalPrice)}
          </Typography>
        )}
      </div>
    </StyledTableHeader>
  );
};
