/* eslint-disable pii/no-phone-number */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SaveIcon from '@mui/icons-material/Save';
import {
  IconButton,
  InputAdornment,
  Grid,
  Typography,
  Skeleton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { find, forEach, includes, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ValidationError } from 'yup';
import {
  Button,
  Checkbox,
  DropdownInput,
  DropdownMultiInput,
  FiltersGrid,
  Form,
  Input,
  PageWrapper,
  TimePicker,
} from '../../../components';
import { USER_CREATE, USER_UPDATE } from '../../../routes';
import { UsersUpdateResponseType } from '../../../services/Users/types';
import {
  fetchUserCreate,
  fetchUserUpdate,
  fetchUsersFilters,
  useDispatch,
  userSlice,
  useSelector,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleErrors } from '../../../utils/Helpers';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { StyledFiltersBtnsGrid } from '../styles';
import { createSchema, lunchTimeSchema, updateSchema } from './validations';

export const UserUpsert: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { usersListLoading } = useSelector((state) => state.usersListReducer);
  const { filtersLoading } = useSelector((state) => state.usersFiltersReducer);
  const { userInfo } = useSelector((state) => state.userReducer);
  const responsiveMode = useResponsiveMode();
  const location = useLocation();
  const [formData, setFormData] = useState<{
    id: number;
    name: string;
    email: string;
    login: string;
    password: string;
    passwordConfirmation: string;
    profileId: string;
    areas: { id: string; value: string }[];
    lunchTimes: {
      startTime: string;
      endTime: string;
      days: {
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
      };
      errors: { [key: string]: string | undefined };
    }[];
    lunchBlock: boolean;
  }>({
    id: 0,
    name: userInfo && location.pathname === USER_UPDATE ? userInfo.name : '',
    email: userInfo && location.pathname === USER_UPDATE ? userInfo.email : '',
    login: userInfo && location.pathname === USER_UPDATE ? userInfo.login : '',
    password: '',
    passwordConfirmation: '',
    profileId:
      userInfo && location.pathname === USER_UPDATE ? userInfo.profileId : '',
    areas: userInfo && location.pathname === USER_UPDATE ? userInfo.areas : [],
    lunchTimes: [],
    lunchBlock: false,
  });
  const [areasFilters, setAreasFilters] = useState<
    { id: string; value: string }[]
  >([]);
  const [profilesFilters, setProfilesFilters] = useState<
    { id: string; value: string }[]
  >([]);
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {},
  );
  const [title, setTitle] = useState('Adicionar usuário');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  useEffect(() => {
    dispatch(fetchUsersFilters()).then(async (res: PayloadAction<any>) => {
      const handledAreasFilters: { id: string; value: string }[] = [];
      const handledProfilesFilters: { id: string; value: string }[] = [];

      map(res.payload.data.areas, (item) => {
        handledAreasFilters.push({
          id: item.id.toString(),
          value: item.id === 0 ? '' : item.value,
        });
      });
      map(res.payload.data.profiles, (item) => {
        handledProfilesFilters.push({
          id: item.id.toString(),
          value: item.id === 0 ? '' : item.value,
        });
      });

      setAreasFilters(handledAreasFilters);
      setProfilesFilters(handledProfilesFilters);
    });
    if (location.pathname === USER_UPDATE) {
      setTitle('Editar usuário');

      const handledLunchTimes =
        userInfo && userInfo.lunchTimes
          ? map(userInfo.lunchTimes, (item) => {
              return {
                startTime: item.startTime,
                endTime: item.endTime,
                days: {
                  monday: includes(item.days, 1),
                  tuesday: includes(item.days, 2),
                  wednesday: includes(item.days, 3),
                  thursday: includes(item.days, 4),
                  friday: includes(item.days, 5),
                  saturday: includes(item.days, 6),
                  sunday: includes(item.days, 7),
                },
                errors: {},
              };
            })
          : [];

      setFormData({
        id: userInfo && userInfo.id ? userInfo.id : 0,
        name:
          userInfo && location.pathname === USER_UPDATE ? userInfo.name : '',
        email:
          userInfo && location.pathname === USER_UPDATE ? userInfo.email : '',
        login:
          userInfo && location.pathname === USER_UPDATE ? userInfo.login : '',
        password: '',
        passwordConfirmation: '',
        profileId:
          userInfo && location.pathname === USER_UPDATE
            ? userInfo.profileId
            : '',
        areas:
          userInfo && location.pathname === USER_UPDATE ? userInfo.areas : [],
        lunchTimes: handledLunchTimes,
        lunchBlock:
          userInfo && location.pathname === USER_UPDATE
            ? userInfo.lunchBlock
            : false,
      });
    }
  }, []);

  const periodDaysHasAtLeastOneTrue = (days: boolean[]) => {
    let hasAtLeastOneTrue = false;
    find(days, (day) => {
      if (day) hasAtLeastOneTrue = true;
    });
    return hasAtLeastOneTrue;
  };

  const handleSubmit = () => {
    setErrors({});
    let lunchTimesHasErrors = false;

    forEach(formData.lunchTimes, (item: any, index: number) => {
      const handledLunchTimes = formData.lunchTimes;

      lunchTimeSchema
        .validate(item, { abortEarly: false })
        .catch((err: ValidationError | undefined) => {
          lunchTimesHasErrors = true;
          handledLunchTimes[index].errors = handleErrors(err);
        });

      if (!periodDaysHasAtLeastOneTrue(item.days)) {
        lunchTimesHasErrors = true;
        handledLunchTimes[index].errors = {
          ...handledLunchTimes[index].errors,
          days: 'Selecione pelo menos um dia',
        };
      }
      setFormData({
        ...formData,
        lunchTimes: handledLunchTimes,
      });
    });

    if (!lunchTimesHasErrors) {
      updateSchema
        .validate(formData, { abortEarly: false })
        .then(() => {
          if (!lunchTimesHasErrors) {
            const handledLunchTimes: {
              startTime: string;
              endTime: string;
              days: number[];
            }[] = map(formData.lunchTimes, (item) => {
              const days = [];
              if (item.days.monday) days.push(1);
              if (item.days.tuesday) days.push(2);
              if (item.days.wednesday) days.push(3);
              if (item.days.thursday) days.push(4);
              if (item.days.friday) days.push(5);
              if (item.days.saturday) days.push(6);
              if (item.days.sunday) days.push(7);

              return {
                startTime: item.startTime,
                endTime: item.endTime,
                days: days,
              };
            });

            if (location.pathname === USER_UPDATE) {
              const areasAsNumber: number[] = [];
              map(formData.areas, (item) => {
                areasAsNumber.push(parseInt(item.id));
              });
              dispatch(
                fetchUserUpdate({
                  userId: userInfo ? userInfo.id.toString() : '0',
                  login: formData.login,
                  name: formData.name,
                  email: formData.email,
                  profileId: parseInt(formData.profileId),
                  areas: areasAsNumber,
                  lunchTimes: handledLunchTimes,
                  lunchBlock: formData.lunchBlock ? 1 : 0,
                }),
              )
                .then((res) => {
                  const response =
                    res.payload as AxiosResponse<UsersUpdateResponseType>; // Cast the payload to the correct type
                  if (response.status === 200) {
                    navigate(-1);
                  }
                })
                .catch((err) => {
                  setErrors(handleErrors(err));
                });
            } else {
              createSchema
                .validate(formData, { abortEarly: false })
                .then(() => {
                  const areasAsNumber: number[] = [];
                  map(formData.areas, (item) => {
                    areasAsNumber.push(parseInt(item.id));
                  });
                  dispatch(
                    fetchUserCreate({
                      login: formData.login,
                      name: formData.name,
                      email: formData.email,
                      profileId: parseInt(formData.profileId),
                      areas: areasAsNumber,
                      password: parseInt(formData.password),
                      passwordConfirmation: parseInt(
                        formData.passwordConfirmation,
                      ),
                      lunchTimes: handledLunchTimes,
                      lunchBlock: formData.lunchBlock ? 1 : 0,
                    }),
                  ).then((res) => {
                    const response =
                      res.payload as AxiosResponse<UsersUpdateResponseType>; // Cast the payload to the correct type
                    if (response && response.status === 200) {
                      navigate(-1);
                    }
                  });
                })
                .catch((err) => {
                  setErrors(handleErrors(err));
                });
            }
          }
        })
        .catch((err) => {
          setErrors(handleErrors(err));
        });
    }
  };

  const handleReturn = () => {
    dispatch(userSlice.actions.resetUserInfo());
    navigate(-1);
  };

  const addNewEmptyLunchTimeRow = () => {
    const newLunchTimes = formData.lunchTimes;
    newLunchTimes.push({
      startTime: '',
      endTime: '',
      days: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      errors: {},
    });
    setFormData({
      ...formData,
      lunchTimes: newLunchTimes,
    });
    setErrors({ ...errors, lunchTimes: '' });
  };

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <PeopleAltIcon fontSize='large' sx={{ marginRight: 2 }} />
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container margin={3}>
        <Form onSubmit={handleSubmit}>
          <FiltersGrid
            loading={
              filtersLoading ||
              areasFilters.length === 0 ||
              profilesFilters.length === 0
            }
            loadingRows={1}
          >
            <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
              <Input
                data-testid='name'
                type='text'
                margin='normal'
                label='Nome'
                value={formData.name}
                onChange={(value) => setFormData({ ...formData, name: value })}
                errorMessage={errors && errors.name}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
              <Input
                data-testid='email'
                type='text'
                margin='normal'
                label='Email'
                value={formData.email}
                onChange={(value) => setFormData({ ...formData, email: value })}
                errorMessage={errors && errors.email}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
              <DropdownInput
                label='Perfil'
                value={formData.profileId}
                options={profilesFilters}
                onChange={(value) => {
                  setFormData({ ...formData, profileId: value });
                }}
                errorMessage={errors && errors.profileId}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
              <DropdownMultiInput
                options={areasFilters}
                defaultValue={
                  userInfo && location.pathname === USER_UPDATE
                    ? userInfo.areas
                    : []
                }
                label='Áreas'
                value={userInfo ? userInfo.areas : []}
                onChange={(value) => {
                  setFormData({ ...formData, areas: value });
                  // setSelectedAreas(value);
                }}
                errorMessage={errors && errors.areas}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
              <Input
                data-testid='login'
                type='text'
                margin='normal'
                label='Login'
                value={formData.login}
                onChange={(value) => setFormData({ ...formData, login: value })}
                errorMessage={errors && errors.login}
              />
            </Grid>
            {location.pathname === USER_CREATE && (
              <>
                <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
                  <Input
                    data-testid='password'
                    type={showPassword ? 'text' : 'password'}
                    label='Senha'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={formData.password}
                    onChange={(InputProps) =>
                      setFormData({ ...formData, password: InputProps })
                    }
                    errorMessage={errors && errors.password}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
                  <Input
                    data-testid='passwordConfirmation'
                    type={showPasswordConfirmation ? 'text' : 'password'}
                    label='Confirme a Senha'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() =>
                              setShowPasswordConfirmation(
                                !showPasswordConfirmation,
                              )
                            }
                            onMouseDown={() =>
                              setShowPasswordConfirmation(
                                !showPasswordConfirmation,
                              )
                            }
                          >
                            {showPasswordConfirmation ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={formData.passwordConfirmation}
                    onChange={(InputProps) =>
                      setFormData({
                        ...formData,
                        passwordConfirmation: InputProps,
                      })
                    }
                    errorMessage={errors && errors.passwordConfirmation}
                  />
                </Grid>
              </>
            )}
            <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
              <Typography component='div' sx={{ marginTop: 2, width: '100%' }}>
                Horários de almoço
              </Typography>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              margin={1}
              marginBottom={2}
            >
              {usersListLoading ? (
                <Skeleton animation='wave' width='100%' height='300px' />
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table
                      // sx={{ minWidth: 500 }}
                      size='small'
                      aria-label='a dense table'
                    >
                      <TableHead>
                        <TableRow>
                          <Checkbox
                            label='Bloquear horário de almoço'
                            checked={formData.lunchBlock}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                lunchBlock: e.target.checked,
                              })
                            }
                          />
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Button
                              variant='text'
                              startIcon={<AddCircleIcon />}
                              label='Novo horário de almoço'
                              style={{ margin: 0 }}
                              // loading={areaCancelLoading}
                              onClick={addNewEmptyLunchTimeRow}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              color='primary.main'
                              sx={{ fontSize: '14px', fontWeight: 'bold' }}
                            >
                              Dias da semana
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '120px' }}>
                            <Typography
                              color='primary.main'
                              sx={{ fontSize: '14px', fontWeight: 'bold' }}
                            >
                              Horário Inicial
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ minWidth: '120px' }}>
                            <Typography
                              color='primary.main'
                              sx={{ fontSize: '14px', fontWeight: 'bold' }}
                            >
                              Horário Final
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formData.lunchTimes.length > 0 ? (
                          map(formData.lunchTimes, (item, index) => {
                            return (
                              <TableRow
                                sx={{
                                  '&:nth-of-type(odd)': {
                                    backgroundColor: palette.action.hover,
                                  },
                                  '&:last-child td, &:last-child th': {
                                    borderBottom: 0.1,
                                    borderColor: palette.divider,
                                  },
                                  height: '55px',
                                }}
                              >
                                <TableCell
                                  component='th'
                                  scope='row'
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div
                                    style={{
                                      // height: '31.5px',
                                      borderRadius: '4px',
                                      paddingRight:
                                        responsiveMode === 'phone' ||
                                        responsiveMode === 'tablet'
                                          ? 0
                                          : '10px',
                                      display:
                                        responsiveMode === 'phone' ||
                                        responsiveMode === 'tablet'
                                          ? 'block'
                                          : 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                      border:
                                        item.errors &&
                                        item.errors.days !== undefined &&
                                        item.errors.days !== ''
                                          ? '1px solid red'
                                          : 0,
                                    }}
                                  >
                                    <Checkbox
                                      label='Seg'
                                      checked={
                                        formData.lunchTimes[index].days.monday
                                      }
                                      onChange={() => {
                                        const handledLunchTimes =
                                          formData.lunchTimes;
                                        handledLunchTimes[index] = {
                                          ...item,
                                          days: {
                                            ...item.days,
                                            monday: !item.days.monday,
                                          },
                                        };
                                        handledLunchTimes[index].errors = {
                                          ...formData.lunchTimes[index].errors,
                                          days: '',
                                        };
                                        setFormData({
                                          ...formData,
                                          lunchTimes: handledLunchTimes,
                                        });
                                      }}
                                    />
                                    <Checkbox
                                      label='Ter'
                                      checked={
                                        formData.lunchTimes[index].days.tuesday
                                      }
                                      onChange={() => {
                                        const handledLunchTimes =
                                          formData.lunchTimes;
                                        handledLunchTimes[index] = {
                                          ...item,
                                          days: {
                                            ...item.days,
                                            tuesday: !item.days.tuesday,
                                          },
                                        };
                                        handledLunchTimes[index].errors = {
                                          ...formData.lunchTimes[index].errors,
                                          days: '',
                                        };
                                        setFormData({
                                          ...formData,
                                          lunchTimes: handledLunchTimes,
                                        });
                                      }}
                                    />
                                    <Checkbox
                                      label='Qua'
                                      checked={
                                        formData.lunchTimes[index].days
                                          .wednesday
                                      }
                                      onChange={() => {
                                        const handledLunchTimes =
                                          formData.lunchTimes;
                                        handledLunchTimes[index] = {
                                          ...item,
                                          days: {
                                            ...item.days,
                                            wednesday: !item.days.wednesday,
                                          },
                                        };
                                        handledLunchTimes[index].errors = {
                                          ...formData.lunchTimes[index].errors,
                                          days: '',
                                        };
                                        setFormData({
                                          ...formData,
                                          lunchTimes: handledLunchTimes,
                                        });
                                      }}
                                    />
                                    <Checkbox
                                      label='Qui'
                                      checked={
                                        formData.lunchTimes[index].days.thursday
                                      }
                                      onChange={() => {
                                        const handledLunchTimes =
                                          formData.lunchTimes;
                                        handledLunchTimes[index] = {
                                          ...item,
                                          days: {
                                            ...item.days,
                                            thursday: !item.days.thursday,
                                          },
                                        };
                                        handledLunchTimes[index].errors = {
                                          ...formData.lunchTimes[index].errors,
                                          days: '',
                                        };
                                        setFormData({
                                          ...formData,
                                          lunchTimes: handledLunchTimes,
                                        });
                                      }}
                                    />
                                    <Checkbox
                                      label='Sex'
                                      checked={
                                        formData.lunchTimes[index].days.friday
                                      }
                                      onChange={() => {
                                        const handledLunchTimes =
                                          formData.lunchTimes;
                                        handledLunchTimes[index] = {
                                          ...item,
                                          days: {
                                            ...item.days,
                                            friday: !item.days.friday,
                                          },
                                        };
                                        handledLunchTimes[index].errors = {
                                          ...formData.lunchTimes[index].errors,
                                          days: '',
                                        };
                                        setFormData({
                                          ...formData,
                                          lunchTimes: handledLunchTimes,
                                        });
                                      }}
                                    />
                                    <Checkbox
                                      label='Sáb'
                                      checked={
                                        formData.lunchTimes[index].days.saturday
                                      }
                                      onChange={() => {
                                        const handledLunchTimes =
                                          formData.lunchTimes;
                                        handledLunchTimes[index] = {
                                          ...item,
                                          days: {
                                            ...item.days,
                                            saturday: !item.days.saturday,
                                          },
                                        };
                                        handledLunchTimes[index].errors = {
                                          ...formData.lunchTimes[index].errors,
                                          days: '',
                                        };
                                        setFormData({
                                          ...formData,
                                          lunchTimes: handledLunchTimes,
                                        });
                                      }}
                                    />
                                    <Checkbox
                                      label='Dom'
                                      checked={
                                        formData.lunchTimes[index].days.sunday
                                      }
                                      onChange={() => {
                                        const handledLunchTimes =
                                          formData.lunchTimes;
                                        handledLunchTimes[index] = {
                                          ...item,
                                          days: {
                                            ...item.days,
                                            sunday: !item.days.sunday,
                                          },
                                        };
                                        handledLunchTimes[index].errors = {
                                          ...formData.lunchTimes[index].errors,
                                          days: '',
                                        };
                                        setFormData({
                                          ...formData,
                                          lunchTimes: handledLunchTimes,
                                        });
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  <TimePicker
                                    value={formData.lunchTimes[index].startTime}
                                    onChange={(value) => {
                                      const handledLunchTimes =
                                        formData.lunchTimes;
                                      handledLunchTimes[index].startTime =
                                        value;
                                      handledLunchTimes[index].errors = {
                                        ...formData.lunchTimes[index].errors,
                                        startTime: '',
                                      };
                                      setFormData({
                                        ...formData,
                                        lunchTimes: handledLunchTimes,
                                      });
                                    }}
                                    variant='outlined'
                                    margin='none'
                                    error={
                                      item.errors &&
                                      item.errors.startTime !== undefined &&
                                      item.errors.startTime !== ''
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component='th'
                                  scope='row'
                                  sx={{ padding: 0 }}
                                >
                                  <TimePicker
                                    value={formData.lunchTimes[index].endTime}
                                    onChange={(value) => {
                                      const handledLunchTimes =
                                        formData.lunchTimes;
                                      handledLunchTimes[index].endTime = value;
                                      handledLunchTimes[index].errors = {
                                        ...formData.lunchTimes[index].errors,
                                        endTime: '',
                                      };
                                      setFormData({
                                        ...formData,
                                        lunchTimes: handledLunchTimes,
                                      });
                                    }}
                                    variant='outlined'
                                    margin='none'
                                    error={
                                      item.errors &&
                                      item.errors.endTime !== undefined &&
                                      item.errors &&
                                      item.errors.endTime !== ''
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component='th'
                                  scope='row'
                                  align='right'
                                  sx={{ width: '35px', padding: 0 }}
                                >
                                  <IconButton
                                    color='secondary'
                                    aria-label='Remover linha'
                                    onClick={() => {
                                      const handledLunchTimes =
                                        formData.lunchTimes;
                                      handledLunchTimes.splice(index, 1);
                                      setFormData({
                                        ...formData,
                                        lunchTimes: handledLunchTimes,
                                      });
                                    }}
                                  >
                                    <CancelIcon fontSize='large' />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow
                            sx={{
                              '&:nth-of-type(odd)': {
                                backgroundColor: palette.action.hover,
                              },
                              '&:last-child td, &:last-child th': {
                                borderBottom:
                                  errors &&
                                  errors.lunchTimes !== undefined &&
                                  errors.lunchTimes !== ''
                                    ? 2
                                    : 1,
                                borderColor:
                                  errors &&
                                  errors.lunchTimes !== undefined &&
                                  errors.lunchTimes !== ''
                                    ? 'red'
                                    : palette.divider,
                              },
                            }}
                          >
                            <TableCell
                              align='center'
                              colSpan={4}
                              sx={{ height: '55px' }}
                            >
                              <Typography
                                sx={{
                                  color:
                                    errors &&
                                    errors.lunchTimes !== undefined &&
                                    errors.lunchTimes !== ''
                                      ? 'red'
                                      : 'grey',
                                }}
                              >
                                Nenhum horário de almoço inserido
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Grid>
            <StyledFiltersBtnsGrid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              margin={1}
            >
              <Button
                variant='contained'
                label='Salvar'
                startIcon={<SaveIcon />}
                onClick={handleSubmit}
                sx={{ width: '100%' }}
                disabled={usersListLoading}
              />
              <Button
                color='secondary'
                variant='contained'
                label='Voltar'
                startIcon={<ArrowBackIcon />}
                onClick={handleReturn}
                sx={{ width: '100%' }}
                disabled={usersListLoading}
              />
            </StyledFiltersBtnsGrid>
          </FiltersGrid>
        </Form>
      </Grid>
    </PageWrapper>
  );
};
