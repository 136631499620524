import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import {
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Skeleton,
} from '@mui/material';
import { omit, replace, times } from 'lodash';
import React, { useState } from 'react';
import VMasker from 'vanilla-masker';
import * as Yup from 'yup';
import { Button, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  notificationSlice,
  fetchNotificationsList,
  fetchNotificationEditPrice,
  fetchNotificationsTotals,
} from '../../../stores';
import { handleErrors, handleMoney } from '../../../utils/Helpers';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const NotificationEditPriceModal: React.FC = () => {
  const dispatch = useDispatch();
  const { login } = useSelector((state) => state.authReducer);
  const { filters } = useSelector((state) => state.notificationsFiltersReducer);
  const {
    notificationInfo,
    notificationEditPriceLoading,
    notificationEditPriceIsOpen,
  } = useSelector((state) => state.notificationReducer);
  const filtersValues = useSelector(
    (state) => state.notificationsFiltersValuesReducer,
  );
  const [formData, setFormData] = useState({
    price: '',
    password: '',
    observation: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object({
    price: Yup.string()
      .required('Insira um novo preço')
      .required('O preço é obrigatório'),
    password: Yup.string()
      .required('Insira sua senha')
      .required('A senha é obrigatória'),
    observation: Yup.string()
      .required('Insira uma observação')
      .required('A observação é obrigatória'),
  });

  const handleClose = () => {
    setErrors({});
    setFormData({
      price: '',
      password: '',
      observation: '',
    });
    notificationSlice.actions.setNotificationEditPriceId({
      notificationId: '',
    });
    dispatch(notificationSlice.actions.toggleNotificationEditPriceIsOpen());
  };

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(
        () =>
          notificationInfo &&
          dispatch(
            fetchNotificationEditPrice({
              notificationId: notificationInfo.id,
              price: replace(formData.price.replaceAll('.', ''), ',', '.'),
              observation: formData.observation,
              login: login,
              password: formData.password,
            }),
          ).then(() => {
            handleClose();
            dispatch(
              fetchNotificationsList(omit(filtersValues, ['days'])),
            ).then(() => {
              dispatch(fetchNotificationsTotals(filtersValues));
            });
          }),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReturn = () => {
    setErrors({});
    setFormData({
      price: '',
      password: '',
      observation: '',
    });
    notificationSlice.actions.setNotificationEditPriceId({
      notificationId: '',
    });
    dispatch(notificationSlice.actions.toggleNotificationEditPriceIsOpen());
    dispatch(notificationSlice.actions.toggleNotificationInfoIsOpen());
  };

  return (
    <Modal
      open={notificationEditPriceIsOpen}
      onClose={handleClose}
      title='Edição de preço'
    >
      <>
        {notificationEditPriceLoading ? (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              {times(1, () => (
                <Skeleton animation='wave' height={29} width='100%' />
              ))}
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        ) : (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Preço:
                </Typography>
                <Typography>
                  {notificationInfo?.price
                    ? handleMoney(notificationInfo?.price)
                    : '-'}
                </Typography>
              </StyledModalTextRow>
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: '10px',
          }}
        >
          <Input
            data-testid='price'
            label='Novo preço'
            placeholder='0,00'
            value={formData.price}
            onChange={(value) =>
              setFormData({ ...formData, price: VMasker.toMoney(value) })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>R$</InputAdornment>
              ),
            }}
            errorMessage={errors && errors.price}
          />
          <Input
            data-testid='password'
            type={showPassword ? 'text' : 'password'}
            label='Insira sua senha'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={formData.password}
            onChange={(InputProps) =>
              setFormData({ ...formData, password: InputProps })
            }
            errorMessage={errors && errors.password}
          />
          <Input
            data-testid='observation'
            label='Observação'
            value={formData.observation}
            onChange={(value) =>
              setFormData({ ...formData, observation: value })
            }
            errorMessage={errors && errors.observation}
          />
          <Button
            variant='contained'
            label='Salvar'
            startIcon={<SaveIcon />}
            type='submit'
            style={{ width: '100%', margin: 0, marginTop: 30 }}
            loading={notificationEditPriceLoading}
            onClick={handleSubmit}
          />
          <Button
            color='secondary'
            variant='contained'
            startIcon={<ArrowBackIcon />}
            label='Voltar'
            type='reset'
            style={{ width: '100%', margin: 0, marginTop: 10 }}
            onClick={handleReturn}
          />
        </Box>
      </>
    </Modal>
  );
};
