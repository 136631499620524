/* eslint-disable lodash/prefer-lodash-method */
import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { NotificationsExportResponseType } from '../../../../services/Notifications/types';
import { notificationsListSlice } from '../../../../stores';
import { delay } from '../../../../utils/Helpers';

export const exportNotificationsToCSV = async (
  apiData: NotificationsExportResponseType,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  worker: (apiData: NotificationsExportResponseType) => Promise<string>,
): Promise<void> => {
  dispatch(notificationsListSlice.actions.setNotificationsExportPercentage(20));

  const handledApiData = await worker(apiData);
  dispatch(notificationsListSlice.actions.setNotificationsExportPercentage(70));

  const fileType = 'data:text/csv;charset=utf-8;';
  const fileExtension = '.csv';
  dispatch(
    notificationsListSlice.actions.setNotificationsExportPercentage(100),
  );
  await delay(100);

  const blob = new Blob([handledApiData], {
    type: fileType,
  });

  FileSaver.saveAs(
    blob,
    `notificacoes-${moment().format('yyyy-MM-DD_hh-mm')}` + fileExtension,
  );

  return;
};

export const handleApiDataNotificationsToCSV = (
  apiData: NotificationsExportResponseType,
) => {
  const formatDateTime = (dateTime: string) => {
    return (
      dateTime[8] +
      dateTime[9] +
      '/' +
      dateTime[5] +
      dateTime[6] +
      '/' +
      dateTime[0] +
      dateTime[1] +
      dateTime[2] +
      dateTime[3] +
      ' ' +
      dateTime.slice(11)
    );
  };
  const formatMoney = (money: string) => {
    // eslint-disable-next-line lodash/prefer-lodash-method
    return `R$${money.toString().replace('.', ',')}`;
  };

  let handledApiData = `"Identificador";"Veículo";"Preço";"Usuário";"Hora de notificação";"Tipo da notificação";"Trecho";"Observação";\r\n`;

  apiData.map((item) => {
    const registerDateTime = formatDateTime(item.registerDateTime);
    // .format('DD/MM/YYYY HH:mm:ss')

    handledApiData =
      handledApiData +
      `"${item.id}";"${
        item.type && item.brand
          ? `${item.plate} / ${item.brand} (${item.type})`
          : item.plate
      }";"${item.price ? formatMoney(item.price) : '-'}";"${
        item.userName
      }";"${registerDateTime}";"${
        item.situation === 'C'
          ? 'Cancelada'
          : item.situation === 'I'
          ? 'Irregular'
          : item.situation === 'P'
          ? 'Paga'
          : 'Regular'
      }";"${item.routeName}";"${item.observation}";\r\n`;
  });

  return handledApiData;
};
