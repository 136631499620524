import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Skeleton,
} from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { invokeMap, isArray, map, omit, times } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button, Input, Modal } from '../../../components';
import {
  useDispatch,
  fetchNotificationsList,
  useSelector,
  notificationSlice,
  fetchNotificationCancelList,
  fetchNotificationsTotals,
} from '../../../stores';
import { handleDateTime, handleErrors } from '../../../utils/Helpers';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const NotificationCancelListModal = (props: {
  selectedRows: GridSelectionModel;
}) => {
  const dispatch = useDispatch();
  const { notificationCancelListLoading, notificationCancelListIsOpen } =
    useSelector((state) => state.notificationReducer);
  const filtersValues = useSelector(
    (state) => state.notificationsFiltersValuesReducer,
  );
  const [formData, setFormData] = useState({
    login: '',
    password: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object({
    login: Yup.string()
      .required('Insira seu login')
      .required('O login é obrigatório'),
    password: Yup.string()
      .required('Insira sua senha')
      .required('A senha é obrigatória'),
  });

  const handleClose = () => {
    setErrors({});
    setFormData({
      login: '',
      password: '',
    });
    dispatch(notificationSlice.actions.toggleNotificationCancelListIsOpen());
  };

  const handleSubmit = () => {
    const values = {
      startDateTime:
        filtersValues.startDateTime ||
        moment(moment().format('yyyy-MM-DD') + ' ' + moment().format('00:00'))
          .format('yyyy-MM-DD HH:mm:ss')
          .toString(),
      finalDateTime:
        filtersValues.finalDateTime ||
        moment(moment().format('yyyy-MM-DD') + ' ' + moment().format('HH:mm'))
          .format('yyyy-MM-DD HH:mm:ss')
          .toString(),
      plate: filtersValues.plate || '',
      notificationId: filtersValues.notificationId || '',
      situationId: filtersValues.situationId || '',
      routeAreaId: filtersValues.routeAreaId || '',
      routeId: filtersValues.routeId || '',
      userName: filtersValues.userName || '',
      serviceOrderOrigin: filtersValues.serviceOrderOrigin || '',
      print: filtersValues.print || '',
    };

    // eslint-disable-next-line lodash/prefer-invoke-map
    const ids = map(props.selectedRows, (item) => {
      return item.toString();
    });
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() =>
        dispatch(
          fetchNotificationCancelList({
            notificationIds: ids,
            login: formData.login,
            password: formData.password,
          }),
        ).then(() => {
          handleClose();
          dispatch(fetchNotificationsList(omit(filtersValues, ['days'])));
          dispatch(fetchNotificationsTotals(values));
        }),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReturn = () => {
    setErrors({});
    setFormData({
      login: '',
      password: '',
    });
    dispatch(notificationSlice.actions.toggleNotificationCancelListIsOpen());
  };

  return (
    <Modal
      open={notificationCancelListIsOpen}
      onClose={handleClose}
      title='Deseja cancelar essas notificações?'
    >
      <>
        {notificationCancelListLoading ? (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              {times(4, () => (
                <Skeleton animation='wave' height={29} width='100%' />
              ))}
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        ) : (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <StyledModalTextRow>
                <Typography>
                  <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                    Notificações:
                  </Typography>
                  <Typography> {props.selectedRows.join(', ')}</Typography>
                </Typography>
              </StyledModalTextRow>
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: '10px',
          }}
        >
          <Input
            data-testid='login'
            label='Insira seu login'
            value={formData.login}
            onChange={(value) => setFormData({ ...formData, login: value })}
            errorMessage={errors && errors.login}
          />
          <Input
            data-testid='password'
            type={showPassword ? 'text' : 'password'}
            label='Insira sua senha'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={formData.password}
            onChange={(InputProps) =>
              setFormData({ ...formData, password: InputProps })
            }
            errorMessage={errors && errors.password}
          />
          <Button
            variant='contained'
            label='Cancelar'
            startIcon={<DeleteIcon />}
            type='submit'
            style={{ width: '100%', margin: 0, marginTop: 30 }}
            loading={notificationCancelListLoading}
            onClick={handleSubmit}
          />
          <Button
            color='secondary'
            variant='contained'
            type='reset'
            startIcon={<ArrowBackIcon />}
            label='Voltar'
            style={{ width: '100%', margin: 0, marginTop: 10 }}
            onClick={handleReturn}
          />
        </Box>
      </>
    </Modal>
  );
};
