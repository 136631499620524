import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  fetchNotificationsCancelService,
  fetchNotificationsInfoService,
  fetchNotificationDetailsExportHTMLService,
  fetchNotificationEditVehicleTypeService,
  fetchNotificationsInfoForEditRecordsService,
  fetchNotificationEditRecordsService,
  fetchNotificationEditPriceService,
  fetchNotificationsCancelListService,
} from '../../services';
import { exportNotificationDetailsToHTML } from '../../utils/ExportToHTML';
import { NotificationStateType } from './types';

const initialState: NotificationStateType = {
  notificationInfo: null,
  notificationInfoSituation: '',
  notificationInfoLoading: false,
  notificationInfoIsOpen: false,
  notificationIdToCancel: '',
  notificationCancelIsOpen: false,
  notificationCancelLoading: false,
  notificationCancelListIsOpen: false,
  notificationCancelListLoading: false,
  notificationDetailsExportHTMLLoading: false,
  notificationIdToEditVehicleType: '',
  notificationEditVehicleTypeLoading: false,
  notificationEditVehicleTypeIsOpen: false,
  notificationEditPriceIsOpen: false,
  notificationIdToEditRecords: '',
  notificationIdToEditPrice: '',
  notificationInfoForEditRecords: null,
  notificationInfoForEditRecordsLoading: false,
  notificationEditRecordsLoading: false,
  notificationEditPriceLoading: false,
};

export const fetchNotificationInfo = createAsyncThunk(
  'notificationsInfo',
  async (params: { notificationId: string }) => {
    return await fetchNotificationsInfoService(params);
  },
);

export const fetchNotificationCancel = createAsyncThunk(
  'notificationsCancel',
  async (params: {
    notificationId: string;
    login: string;
    password: string;
  }) => {
    return await fetchNotificationsCancelService(params);
  },
);

export const fetchNotificationCancelList = createAsyncThunk(
  'notificationsCancelList',
  async (params: {
    notificationIds: string[];
    login: string;
    password: string;
  }) => {
    return await fetchNotificationsCancelListService(params);
  },
);

export const fetchNotificationDetailsExportHTML = createAsyncThunk(
  'notificationsDetailsExportHTML',
  async (params: { notificationId: string }) => {
    return await fetchNotificationDetailsExportHTMLService(params);
  },
);

export const fetchNotificationEditVehicleType = createAsyncThunk(
  'notificationsEditVehicleType',
  async (params: {
    notificationId: string;
    type: string;
    login: string;
    password: string;
  }) => {
    return await fetchNotificationEditVehicleTypeService(params);
  },
);

export const fetchNotificationInfoForEditRecords = createAsyncThunk(
  'notificationsInfoForEditRecords',
  async (params: { notificationId: string }) => {
    return await fetchNotificationsInfoForEditRecordsService(params);
  },
);

export const fetchNotificationEditRecords = createAsyncThunk(
  'notificationsEditRecords',
  async (params: {
    notificationId: string;
    registerTime: string;
    records: number[];
    login: string;
    password: string;
    observation: string;
  }) => {
    return await fetchNotificationEditRecordsService(params);
  },
);

export const fetchNotificationEditPrice = createAsyncThunk(
  'notificationsEditPrice',
  async (params: {
    notificationId: string;
    price: string;
    observation: string;
    login: string;
    password: string;
  }) => {
    return await fetchNotificationEditPriceService(params);
  },
);

export const notificationSlice = createSlice({
  name: 'notificationInfo',
  initialState,
  reducers: {
    setNotificationInfoSituation(
      state,
      action: PayloadAction<{ situation: string }>,
    ) {
      state.notificationInfoSituation = action.payload.situation;
    },
    toggleNotificationInfoIsOpen(state) {
      state.notificationInfoIsOpen
        ? (state.notificationInfoIsOpen = false)
        : (state.notificationInfoIsOpen = true);
    },
    setNotificationCancelId(
      state,
      action: PayloadAction<{ notificationId: string }>,
    ) {
      state.notificationIdToCancel = action.payload.notificationId;
    },
    toggleNotificationCancelIsOpen(state) {
      state.notificationCancelIsOpen
        ? (state.notificationCancelIsOpen = false)
        : (state.notificationCancelIsOpen = true);
    },
    toggleNotificationCancelListIsOpen(state) {
      state.notificationCancelListIsOpen
        ? (state.notificationCancelListIsOpen = false)
        : (state.notificationCancelListIsOpen = true);
    },
    toggleNotificationEditVehicleTypeIsOpen(state) {
      state.notificationEditVehicleTypeIsOpen
        ? (state.notificationEditVehicleTypeIsOpen = false)
        : (state.notificationEditVehicleTypeIsOpen = true);
    },
    toggleNotificationEditPriceIsOpen(state) {
      state.notificationEditPriceIsOpen
        ? (state.notificationEditPriceIsOpen = false)
        : (state.notificationEditPriceIsOpen = true);
    },
    setNotificationEditVehicleTypeId(
      state,
      action: PayloadAction<{ notificationId: string }>,
    ) {
      state.notificationIdToEditVehicleType = action.payload.notificationId;
    },
    setNotificationEditRecordsId(
      state,
      action: PayloadAction<{ notificationId: string }>,
    ) {
      state.notificationIdToEditRecords = action.payload.notificationId;
    },
    setNotificationEditPriceId(
      state,
      action: PayloadAction<{ notificationId: string }>,
    ) {
      state.notificationIdToEditPrice = action.payload.notificationId;
    },
    toggleNotificationRegister(
      state,
      action: PayloadAction<{ index: number }>,
    ) {
      if (state.notificationInfoForEditRecords) {
        state.notificationInfoForEditRecords.records[
          action.payload.index
        ].checked =
          !state.notificationInfoForEditRecords.records[action.payload.index]
            .checked;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationInfo.pending, (state) => {
      return {
        ...state,
        notificationInfoLoading: true,
      };
    }),
      builder.addCase(fetchNotificationInfo.fulfilled, (state, res) => {
        return {
          ...state,
          notificationInfo: {
            id: res.payload.data.id,
            situation: res.payload.data.situation,
            registerDateTime: res.payload.data.registerDateTime,
            plate: res.payload.data.plate,
            type: res.payload.data.type,
            brand: res.payload.data.brand,
            observation: res.payload.data.observation,
            latitude: res.payload.data.latitude,
            longitude: res.payload.data.longitude,
            price: res.payload.data.price,
            plateImg: res.payload.data.plateImg,
            locationImg: res.payload.data.locationImg,
            routeName: res.payload.data.routeName,
            userName: res.payload.data.userName,
            userLogin: res.payload.data.userLogin,
            serviceOrderId: res.payload.data.serviceOrderId,
            serviceOrderDateTime: res.payload.data.serviceOrderDateTime,
            serviceOrderEntryDateTime:
              res.payload.data.serviceOrderEntryDateTime,
            serviceOrderExitDateTime: res.payload.data.serviceOrderExitDateTime,
            serviceOrderPrice: res.payload.data.serviceOrderPrice,
            credentialId: res.payload.data.credentialId,
            credentialUserName: res.payload.data.credentialUserName,
          },
          notificationInfoLoading: false,
        };
      }),
      builder.addCase(fetchNotificationInfo.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          notificationInfoLoading: false,
        };
      });

    builder.addCase(fetchNotificationCancel.pending, (state) => {
      return {
        ...state,
        notificationCancelLoading: true,
      };
    }),
      builder.addCase(fetchNotificationCancel.fulfilled, (state, res) => {
        toast.success('Notificação cancelada com sucesso.');
        return {
          ...state,
          notificationCancelLoading: false,
        };
      }),
      builder.addCase(fetchNotificationCancel.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          notificationCancelLoading: false,
        };
      });

    builder.addCase(fetchNotificationCancelList.pending, (state) => {
      return {
        ...state,
        notificationCancelListLoading: true,
      };
    }),
      builder.addCase(fetchNotificationCancelList.fulfilled, (state, res) => {
        toast.success('Notificações canceladas com sucesso.');
        return {
          ...state,
          notificationCancelListLoading: false,
        };
      }),
      builder.addCase(fetchNotificationCancelList.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          notificationCancelListLoading: false,
        };
      });

    builder.addCase(fetchNotificationDetailsExportHTML.pending, (state) => {
      return {
        ...state,
        notificationDetailsExportHTMLLoading: true,
      };
    }),
      builder.addCase(
        fetchNotificationDetailsExportHTML.fulfilled,
        (state, res) => {
          exportNotificationDetailsToHTML(res.payload.data);
          return {
            ...state,
            notificationDetailsExportHTMLLoading: false,
          };
        },
      ),
      builder.addCase(fetchNotificationDetailsExportHTML.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          notificationDetailsExportHTMLLoading: false,
        };
      });

    builder.addCase(fetchNotificationEditVehicleType.pending, (state) => {
      return {
        ...state,
        notificationEditVehicleTypeLoading: true,
      };
    }),
      builder.addCase(
        fetchNotificationEditVehicleType.fulfilled,
        (state, res) => {
          toast.success('Tipo de veículo editado com sucesso.');
          return {
            ...state,
            notificationEditVehicleTypeLoading: false,
          };
        },
      ),
      builder.addCase(fetchNotificationEditVehicleType.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          notificationEditVehicleTypeLoading: false,
        };
      });

    builder.addCase(fetchNotificationInfoForEditRecords.pending, (state) => {
      return {
        ...state,
        notificationInfoForEditRecordsLoading: true,
      };
    }),
      builder.addCase(
        fetchNotificationInfoForEditRecords.fulfilled,
        (state, res) => {
          const handledRecords = map(res.payload.data.records, (record) => {
            return { ...record, checked: false };
          });
          return {
            ...state,
            notificationInfoForEditRecords: {
              id: res.payload.data.id,
              registerDateTime: res.payload.data.registerDateTime,
              plate: res.payload.data.plate,
              records: handledRecords,
            },
            notificationInfoForEditRecordsLoading: false,
          };
        },
      ),
      builder.addCase(fetchNotificationInfoForEditRecords.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          notificationInfoForEditRecordsLoading: false,
        };
      });

    builder.addCase(fetchNotificationEditRecords.pending, (state) => {
      return {
        ...state,
        notificationEditRecordsLoading: true,
      };
    }),
      builder.addCase(fetchNotificationEditRecords.fulfilled, (state, res) => {
        toast.success('Registros editados com sucesso.');
        return {
          ...state,
          notificationEditRecordsLoading: false,
        };
      }),
      builder.addCase(fetchNotificationEditRecords.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          notificationEditRecordsLoading: false,
        };
      });

    builder.addCase(fetchNotificationEditPrice.pending, (state) => {
      return {
        ...state,
        notificationEditPriceLoading: true,
      };
    }),
      builder.addCase(fetchNotificationEditPrice.fulfilled, (state, res) => {
        toast.success('Preço editado com sucesso.');
        return {
          ...state,
          notificationEditPriceLoading: false,
        };
      }),
      builder.addCase(fetchNotificationEditPrice.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          notificationEditPriceLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setNotificationInfoSituation,
  toggleNotificationInfoIsOpen,
  toggleNotificationCancelIsOpen,
  toggleNotificationCancelListIsOpen,
  setNotificationCancelId,
} = notificationSlice.actions;

export default notificationSlice.reducer;
