export const fetchNotificationsFiltersUrl = `/notifications/list/filters`;
export const fetchNotificationsListUrl = `/notifications`;
export const fetchNotificationsTotalsUrl = `/notifications/list/totals`;
export const fetchNotificationsInfoUrl = `/notifications`;
export const fetchNotificationsCancelUrl = `/notifications`;
export const fetchNotificationsCancelListUrl = `/notifications/cancel/list`;
export const fetchNotificationDetailsExportHTMLUrl = `/notifications/export`;
export const fetchNotificationsExportExcelUrl = `/notifications/export/excel`;
export const fetchNotificationsExportHTMLUrl = `/notifications/export/html`;
export const fetchNotificationsExportFinesHTMLUrl = `/notifications/export/html/dailyfine`;
export const fetchNotificationsEditVehicleTypeUrl = `/notifications`;
export const fetchNotificationsEditPriceUrl = `/notifications`;
export const fetchNotificationsExportReceiptHTMLUrl = `/notifications/export/html/receipt`;
