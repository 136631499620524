import {
  Inventory,
  CancelRounded,
  FilterAlt,
  RemoveRedEye,
} from '@mui/icons-material';
import {
  Button as MuiButton,
  CircularProgress,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import {
  PageWrapper,
  FiltersGrid,
  Accordion,
  Input,
  DropdownInput,
  DatePicker,
  Button,
  Table,
  Modal,
} from '../../components';

import { ActionsPageResponseType } from '../../services/Actions/types';
import {
  useDispatch,
  useSelector,
  fetchActionsHistoryList,
  fetchActionsHistoryFilters,
  fetchActionsHistoryView,
  setActionsHistoryListCurrentPage,
  setActionsFiltersValues,
  resetActionsFiltersValues,
  clearActions,
} from '../../stores';
import { palette } from '../../theme';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { StyledFiltersBtnsGrid } from './styles';

export const ActionsHistory: React.FC = () => {
  const columns = [
    {
      field: 'userName',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: ActionsPageResponseType }) => (
        <Typography>
          {params.row.userName} ({params.row.userLogin})
        </Typography>
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'userProfile',
      headerName: 'Perfil',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: ActionsPageResponseType }) => (
        <Typography>{params.row.userProfile}</Typography>
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actionName',
      headerName: 'Ação',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: ActionsPageResponseType }) => (
        <Typography>
          {params?.row?.permissionName || params?.row?.actionName || ''}
        </Typography>
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'dateTime',
      headerName: 'Dia e Hora',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: ActionsPageResponseType }) => (
        <Typography>
          {moment(params?.row?.dateTime).format('DD/MM/YYYY HH:mm:ss')}
        </Typography>
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      editable: false,
      sortable: false,
      renderCell: (params: { row: ActionsPageResponseType }) => (
        <MuiButton
          variant='contained'
          startIcon={<RemoveRedEye />}
          size='small'
          onClick={() => handleEdit(params.row.id)}
          sx={{ paddingLeft: 1, paddingRight: 1 }}
        >
          Detalhar
        </MuiButton>
      ),
      flex: 1,
    },
  ];

  const columnsResponsive = [
    {
      field: 'userName',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: ActionsPageResponseType }) => (
        <Typography>
          {params.row.userName} ({params.row.userLogin})
        </Typography>
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'userProfile',
      headerName: 'Perfil',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: ActionsPageResponseType }) => (
        <Typography>{params.row.userProfile}</Typography>
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actionName',
      headerName: 'Ação',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: ActionsPageResponseType }) => (
        <Typography>
          {params?.row?.permissionName || params?.row?.actionName || ''}
        </Typography>
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'dateTime',
      headerName: 'Dia e Hora',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: ActionsPageResponseType }) => (
        <Typography>
          {moment(params?.row?.dateTime).format('DD/MM/YYYY HH:mm:ss')}
        </Typography>
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: ActionsPageResponseType }) => (
        <MuiButton
          variant='contained'
          size='small'
          onClick={() => handleEdit(params.row.id)}
        >
          <RemoveRedEye />
        </MuiButton>
      ),
      headerClassName: 'super-app-theme--header',
    },
  ];

  const dispatch = useDispatch();

  const responsiveMode = useResponsiveMode();

  const filterValues = useSelector(
    (state) => state.actionsFiltersValuesReducer,
  );

  const {
    isLoading,
    filters: { permissions, profiles },
  } = useSelector((state) => state.actionsFiltersReducer);

  const { isListLoading, size, page, currentPage } = useSelector(
    (state) => state.actionsListReducer,
  );

  const { isActionsLoading } = useSelector((state) => state.actionsReducer);
  const actionsDetail = useSelector((state) => state.actionsReducer);

  const [isDetalingModalOpen, setIsDetalingModalOpen] =
    useState<boolean>(false);
  const [isFilterAccordionExpanded, setIsFilterAccordionExpanded] =
    useState<boolean>(false);
  const [rows, setRows] = useState<object[]>([]);

  useEffect(() => {
    dispatch(fetchActionsHistoryFilters());
  }, []);

  useEffect(() => {
    if (page) {
      setRows(page);
    }
  }, [page]);

  useEffect(() => {
    dispatch(
      fetchActionsHistoryList({
        userName: filterValues.userName,
        permissionId: filterValues.permissions,
        profileId: filterValues.profile,
        startDateTime: `${
          filterValues.startDate || moment().format('YYYY-MM-DD')
        } 00:00:00`,
        finalDateTime: `${
          filterValues.finalDate || moment().format('YYYY-MM-DD')
        } 23:59:59`,
      }),
    );
  }, []);

  const handleFilter = () => {
    dispatch(
      fetchActionsHistoryList({
        userName: filterValues.userName,
        permissionId: filterValues.permissions,
        profileId: filterValues.profile,
        startDateTime: `${
          filterValues.startDate || moment().format('YYYY-MM-DD')
        } 00:00:00`,
        finalDateTime: `${
          filterValues.finalDate || moment().format('YYYY-MM-DD')
        } 23:59:59`,
      }),
    );

    dispatch(setActionsHistoryListCurrentPage(1));
  };

  const handleClearFilter = () => {
    dispatch(setActionsHistoryListCurrentPage(1));
    dispatch(resetActionsFiltersValues());

    dispatch(
      fetchActionsHistoryList({
        userName: '',
        permissionId: 0,
        profileId: 0,
        startDateTime: `${moment().format('YYYY-MM-DD')} 00:00:00`,
        finalDateTime: `${moment().format('YYYY-MM-DD')} 23:59:59`,
      }),
    );
  };

  const handleEdit = (id: number) => {
    dispatch(clearActions());

    dispatch(fetchActionsHistoryView({ id })).then((res) => {
      if (res.type == 'actionsHistoryView/fulfilled') {
        setIsDetalingModalOpen(true);
      }
    });
  };

  return (
    <PageWrapper>
      <Modal
        title='Detalhamento'
        open={isDetalingModalOpen}
        onClose={() => setIsDetalingModalOpen(!isDetalingModalOpen)}
        children={
          <>
            {isActionsLoading ? (
              <CircularProgress />
            ) : (
              <Grid container>
                <Grid xs={12} marginBottom={1}>
                  Nome: {actionsDetail.userName}
                </Grid>
                <Grid xs={12} marginBottom={1}>
                  Perfil: {actionsDetail.userProfile}
                </Grid>
                <Grid xs={12} marginBottom={1}>
                  Ação:{' '}
                  {actionsDetail.permissionName ||
                    actionsDetail?.actionName ||
                    ''}
                </Grid>
                <Grid xs={12} marginBottom={1}>
                  Dia e hora:{' '}
                  {moment(actionsDetail.dateTime).format('DD/MM/YYYY HH:mm:ss')}
                </Grid>
                <Grid xs={12} marginBottom={1}>
                  Observação: {actionsDetail.observation}
                </Grid>
              </Grid>
            )}
          </>
        }
      />
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <Inventory fontSize='large' sx={{ marginRight: 2 }} />
            Histórico de ações
          </Typography>
        </Grid>
        {responsiveMode !== 'phone' ? (
          <FiltersGrid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                type='text'
                margin='normal'
                label='Nome'
                value={filterValues.userName}
                onChange={(value) =>
                  dispatch(
                    setActionsFiltersValues({
                      ...filterValues,
                      userName: value,
                    }),
                  )
                }
              />
            </Grid>
            <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
              <DropdownInput
                label='Perfil'
                options={profiles}
                value={filterValues.profile.toString()}
                onChange={(value) =>
                  dispatch(
                    setActionsFiltersValues({
                      ...filterValues,
                      profile: +value,
                    }),
                  )
                }
              />
            </Grid>
            <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
              <DropdownInput
                label='Ações'
                options={permissions}
                value={filterValues.permissions.toString()}
                onChange={(value) =>
                  dispatch(
                    setActionsFiltersValues({
                      ...filterValues,
                      permissions: +value,
                    }),
                  )
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <DatePicker
                label='Data inicial'
                value={filterValues.startDate}
                onChange={(value) =>
                  dispatch(
                    setActionsFiltersValues({
                      ...filterValues,
                      startDate: value,
                    }),
                  )
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <DatePicker
                label='Data final'
                value={filterValues.finalDate}
                onChange={(value) =>
                  dispatch(
                    setActionsFiltersValues({
                      ...filterValues,
                      finalDate: value,
                    }),
                  )
                }
              />
            </Grid>
            <StyledFiltersBtnsGrid>
              <Button
                variant='contained'
                label='Filtrar'
                startIcon={<FilterAlt />}
                onClick={handleFilter}
                disabled={isLoading}
              />
              <IconButton
                color='secondary'
                aria-label='Remover filtros'
                onClick={handleClearFilter}
                disabled={isLoading}
              >
                <CancelRounded fontSize='large' />
              </IconButton>
            </StyledFiltersBtnsGrid>
          </FiltersGrid>
        ) : (
          <Accordion
            title='Filtros'
            expanded={isFilterAccordionExpanded}
            setExpanded={setIsFilterAccordionExpanded}
          >
            <FiltersGrid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Input
                  type='text'
                  margin='normal'
                  label='Nome'
                  value={filterValues.userName}
                  onChange={(value) =>
                    dispatch(
                      setActionsFiltersValues({
                        ...filterValues,
                        userName: value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Perfil'
                  options={profiles}
                  value={filterValues.profile.toString()}
                  onChange={(value) =>
                    dispatch(
                      setActionsFiltersValues({
                        ...filterValues,
                        profile: +value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Ações'
                  options={permissions}
                  value={filterValues.permissions.toString()}
                  onChange={(value) =>
                    dispatch(
                      setActionsFiltersValues({
                        ...filterValues,
                        permissions: +value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DatePicker
                  label='Data inicial'
                  value={filterValues.startDate}
                  onChange={(value) =>
                    dispatch(
                      setActionsFiltersValues({
                        ...filterValues,
                        startDate: value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DatePicker
                  label='Data final'
                  value={filterValues.finalDate}
                  onChange={(value) =>
                    dispatch(
                      setActionsFiltersValues({
                        ...filterValues,
                        finalDate: value,
                      }),
                    )
                  }
                />
              </Grid>
              <StyledFiltersBtnsGrid>
                <Button
                  variant='contained'
                  label='Filtrar'
                  startIcon={<FilterAlt />}
                  onClick={handleFilter}
                  disabled={isLoading}
                />
                <IconButton
                  color='secondary'
                  aria-label='Remover filtros'
                  onClick={handleClearFilter}
                  disabled={isLoading}
                >
                  <CancelRounded fontSize='large' />
                </IconButton>
              </StyledFiltersBtnsGrid>
            </FiltersGrid>
          </Accordion>
        )}
        <Grid container marginTop={5} marginBottom={5}>
          <Table
            loading={isListLoading}
            changePage={(page: number) =>
              dispatch(setActionsHistoryListCurrentPage(page))
            }
            page={currentPage}
            fetchRows={() => {
              dispatch(
                fetchActionsHistoryList({
                  userName: filterValues.userName,
                  permissionId: filterValues.permissions,
                  profileId: filterValues.profile,
                  startDateTime: `${
                    filterValues.startDate || moment().format('YYYY-MM-DD')
                  } 00:00:00`,
                  finalDateTime: `${
                    filterValues.finalDate || moment().format('YYYY-MM-DD')
                  } 23:59:59`,
                }),
              );
            }}
            rowCount={size}
            rows={rows}
            columns={
              responsiveMode === 'phone' || responsiveMode === 'tablet'
                ? columnsResponsive
                : columns
            }
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};
