import {
  ListAlt,
  Download,
  Save,
  ArrowBack,
  Loop,
  Check,
  Close,
  Cancel,
} from '@mui/icons-material';
import {
  Paper,
  Grid,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import VMasker from 'vanilla-masker';
import {
  PageWrapper,
  Input,
  Checkbox,
  Button,
  DatePicker,
  CEP,
  FileUpload,
  Modal,
} from '../../../components';

import {
  CREDENTIALS_MANAGEMENT,
  CREDENTIALS_CREATE,
  CREDENTIALS_UPDATE,
} from '../../../routes/constants';
import {
  useDispatch,
  useSelector,
  fetchCredentialsView,
  fetchCredentialsUploadDocument,
  fetchCredentialsDownloadDocument,
  fetchCredentialsRenew,
  fetchCredentialsExport,
  clearCredentials,
  setCredentials,
  fetchCredentialsCPF,
  credentialsSlice,
  fetchCredentialsUpdate,
  fetchCredentialsCreate,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleErrors } from '../../../utils/Helpers';

import useResponsiveMode from '../../../utils/useResponsiveMode';

import { Export } from '../Export';
import { DeactivateCredentialModal } from './DeactivateCredentialModal';
import { validation } from './validations';

const PHONE_MAX_LENGTH = 15;
const DOCUMENT_MAX_LENGTH = 14;

export const CredentialsUpsert: React.FC = () => {
  const responsiveMode = useResponsiveMode();

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const [credentialsFormErrors, setCredentialsFormErrors] = useState<{
    [key: string]: string;
  }>({});
  const [canPostalCodeBeFilled, setCanPostalCodeBeFilled] = useState<boolean>(
    location.pathname == CREDENTIALS_CREATE,
  );
  const [isRenewModalOpen, setIsRenewModalOpen] = useState<boolean>(false);
  const [renewDate, setRenewDate] = useState<string>('');
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] =
    useState<boolean>(false);
  const [uploadFormKey, setUploadFormKey] = useState<number>(Date.now());
  const [fileForm, setFileForm] = useState<FormData>();
  const [file, setFile] = useState<File>();

  const { routes } = useSelector(
    (state) => state.credentialsFiltersReducer.filters,
  );
  const { isCredentialsLoading, isCredentialsCPFLoading } = useSelector(
    (state) => state.credentialsReducer,
  );
  const credential = useSelector((state) => state.credentialsReducer);

  useEffect(() => {
    if (location.pathname == CREDENTIALS_CREATE) {
      dispatch(clearCredentials());
    }

    if (location.pathname == CREDENTIALS_UPDATE) {
      dispatch(fetchCredentialsView(credential.id));
    }
  }, []);

  useEffect(() => {
    if (credential.documents) {
      dispatch(
        fetchCredentialsDownloadDocument({ fileName: credential.documents }),
      );
    }
  }, [credential.documents]);

  useEffect(() => {
    if (credential.userDocument.length === 14) {
      dispatch(
        fetchCredentialsCPF({
          cpf: VMasker.toNumber(credential.userDocument).toString(),
        }),
      );
    }
  }, [credential.userDocument]);

  const handleUploadDocument = (
    form: FormData | undefined,
    file: File | undefined,
  ) => {
    if (file) {
      const fileSize = +(file.size / 1024 / 1024).toFixed(4);

      const MAXIMUM_ALLOWED_FILE_SIZE = 5;

      if (
        file.type !== 'application/zip' &&
        file.type !== 'application/octet-stream' &&
        file.type !== 'application/x-zip-compressed' &&
        file.type !== 'multipart/x-zip' &&
        file.type !== 'application/vnd.rar' &&
        file.type !== 'application/x-rar-compressed' &&
        file.type !== 'application/octet-stream' &&
        file.type !== 'application/x-rar'
      ) {
        return toast.error('O formato do arquivo precisa ser .zip ou .rar');
      }

      if (fileSize > MAXIMUM_ALLOWED_FILE_SIZE) {
        return toast.error(
          `O arquivo não pode exceder ${MAXIMUM_ALLOWED_FILE_SIZE} MB`,
        );
      }

      dispatch(fetchCredentialsUploadDocument(form));
    }
  };

  const handleDownloadDocument = () => {
    if (credential.documents) {
      dispatch(
        fetchCredentialsDownloadDocument({ fileName: credential.documents }),
      ).then((res) => {
        if (res.type == 'credentialsDownloadDocument/fulfilled') {
          const blob = new Blob([res.payload]);

          const anchor = document.createElement('a');
          const fileURL = URL.createObjectURL(blob);

          anchor.href = fileURL;
          anchor.setAttribute('download', credential.documents);
          anchor.click();
        }
      });
    }
  };

  const handleSubmit = () => {
    const form = {
      ...credential,
      userDocument: VMasker.toNumber(credential.userDocument),
      userPhone: VMasker.toNumber(credential.userPhone),
      validity: moment(credential.validity).format('YYYY-MM-DD HH:mm:ss'),
      routes: credential.routes.filter((route) => route != 0),
    };

    validation
      .validate(form, { abortEarly: false })
      .then(() => {
        setCredentialsFormErrors({});

        if (credential.id) {
          dispatch(fetchCredentialsUpdate({ ...form, id: credential.id }));
        } else {
          dispatch(fetchCredentialsCreate(form)).then((res) => {
            if (res.type == 'credentialsCreate/fulfilled') {
              navigate(CREDENTIALS_MANAGEMENT);
            }
          });
        }
      })
      .catch((err) => setCredentialsFormErrors(handleErrors(err)));
  };

  const handleRenew = () => {
    if (!renewDate) {
      return toast.error('Selecione uma data de validade.');
    }

    const params = {
      id: credential.id,
      validity: `${renewDate} 23:59:59`,
    };

    dispatch(fetchCredentialsRenew(params)).then((res) => {
      if (res.type == 'credentialsRenew/fulfilled') {
        navigate(CREDENTIALS_MANAGEMENT);
      }
    });
  };

  const handleDeactivateCredential = () => {
    dispatch(credentialsSlice.actions.toggleCredentialDeactivateIsOpen());
  };

  const handleCredentialExport = () => {
    const params = {
      number: credential.number,
      plate: credential.vehiclePlate,
      userName: credential.userName,
      userDocument: credential.userDocument,
      address: credential.address,
      routeId: credential.routes.join(','),
      validity: credential.validity,
    };

    dispatch(fetchCredentialsExport(params)).then(
      (res: { type: string; payload: any }) => {
        if (res.type == 'credentialsExport/fulfilled') {
          const { number, userName, userDocument, observation } =
            res.payload[0];

          const exportation = renderToStaticMarkup(
            <Export
              credential={{ number, userName, userDocument, observation }}
            />,
          );

          const file = new Blob([exportation], {
            type: 'text/html;charset=UTF-8',
          });
          const fileURL = URL.createObjectURL(file);

          const anchor = document.createElement('a');

          anchor.href = fileURL;
          anchor.setAttribute('download', `comprovante-credencial-${number}`);
          anchor.click();
        }
      },
    );
  };

  return (
    <PageWrapper>
      {isCredentialsLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid container justifyContent='space-between'>
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <ListAlt fontSize='large' color='action' />
              <Typography variant='h5' color={palette.text.main} padding={1}>
                {location.pathname == CREDENTIALS_CREATE
                  ? 'Cadastrar Credencial'
                  : 'Editar Credencial'}
              </Typography>
            </Grid>
            {location.pathname == CREDENTIALS_UPDATE && (
              <Grid padding={1}>
                <Button
                  fullWidth
                  variant='contained'
                  label='Geração de comprovante'
                  startIcon={<Download />}
                  onClick={handleCredentialExport}
                />
              </Grid>
            )}
          </Grid>
          <Paper style={{ width: '100%', marginTop: 15 }}>
            <Modal
              title='Renovação'
              open={isRenewModalOpen}
              onClose={() => setIsRenewModalOpen(!isRenewModalOpen)}
              children={
                <Grid container>
                  <Grid xs={12}>
                    <DatePicker
                      label='Data de validade da renovação'
                      value={renewDate}
                      onChange={(value) => setRenewDate(value)}
                    />
                  </Grid>
                  <Grid xs={6} padding={0.5}>
                    <Button
                      fullWidth
                      variant='contained'
                      label='Confirmar'
                      startIcon={<Check />}
                      onClick={handleRenew}
                    />
                  </Grid>
                  <Grid xs={6} padding={0.5}>
                    <Button
                      fullWidth
                      color='secondary'
                      variant='contained'
                      label='Cancelar'
                      startIcon={<Close />}
                      onClick={() => setIsRenewModalOpen(!isRenewModalOpen)}
                    />
                  </Grid>
                </Grid>
              }
            />
            <Modal
              title='Deseja selecionar este arquivo?'
              open={isFileUploadModalOpen}
              onClose={() => setIsFileUploadModalOpen(!isFileUploadModalOpen)}
              children={
                <Grid container>
                  <Grid xs={6} padding={0.5}>
                    <Button
                      fullWidth
                      variant='contained'
                      label='Confirmar'
                      startIcon={<Check />}
                      onClick={() => {
                        handleUploadDocument(fileForm, file);
                        setIsFileUploadModalOpen(!isFileUploadModalOpen);
                      }}
                    />
                  </Grid>
                  <Grid xs={6} padding={0.5}>
                    <Button
                      fullWidth
                      color='secondary'
                      variant='contained'
                      label='Cancelar'
                      startIcon={<Close />}
                      onClick={() => {
                        setUploadFormKey(Date.now());
                        setIsFileUploadModalOpen(!isFileUploadModalOpen);
                      }}
                    />
                  </Grid>
                </Grid>
              }
            />
            <Grid container padding={3}>
              <Typography variant='h5' color={palette.text.main}>
                Informações do credenciado
              </Typography>
            </Grid>
            <Grid container>
              <Grid xs={12} md={4} padding={1}>
                <Input
                  type='text'
                  margin='normal'
                  name='userName'
                  label='Nome'
                  value={credential.userName}
                  onChange={(value) =>
                    dispatch(setCredentials({ ...credential, userName: value }))
                  }
                  errorMessage={credentialsFormErrors.userName}
                />
              </Grid>
              <Grid xs={12} md={4} padding={1}>
                <Input
                  type='text'
                  margin='normal'
                  name='userDocument'
                  label='CPF'
                  value={
                    credential.userDocument
                      ? VMasker.toPattern(
                          credential.userDocument,
                          '999.999.999-99',
                        )
                      : ''
                  }
                  onChange={(value) =>
                    dispatch(
                      setCredentials({ ...credential, userDocument: value }),
                    )
                  }
                  inputProps={{ maxLength: DOCUMENT_MAX_LENGTH }}
                  errorMessage={credentialsFormErrors.userDocument}
                  InputProps={
                    isCredentialsCPFLoading && {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CircularProgress color='primary' size={24} />
                        </InputAdornment>
                      ),
                    }
                  }
                />
              </Grid>
              <Grid xs={12} md={4} padding={1}>
                <Input
                  type='text'
                  margin='normal'
                  label='Telefone'
                  value={
                    credential.userPhone
                      ? VMasker.toPattern(
                          credential.userPhone,
                          '(99) 99999-9999',
                        )
                      : ''
                  }
                  onChange={(value) =>
                    dispatch(
                      setCredentials({ ...credential, userPhone: value }),
                    )
                  }
                  inputProps={{ maxLength: PHONE_MAX_LENGTH }}
                />
              </Grid>
              <Grid xs={12} md={4} padding={1}>
                <CEP
                  id='credentialsUpsertAddressCode'
                  name='addressCode'
                  value={credential.addressCode}
                  onChange={(postalCode) => {
                    setCanPostalCodeBeFilled(true);

                    if (!canPostalCodeBeFilled) return;

                    if (!postalCode.erro) {
                      dispatch(
                        setCredentials({
                          ...credential,
                          addressCode: VMasker.toNumber(postalCode.cep),
                          address: postalCode.logradouro,
                          addressComplement: postalCode.complemento,
                          district: postalCode.bairro,
                          city: postalCode.localidade,
                          state: postalCode.uf,
                        }),
                      );
                    }
                  }}
                />
              </Grid>
              <Grid xs={12} md={8} padding={1}>
                <Input
                  type='text'
                  margin='normal'
                  name='address'
                  label='Logradouro'
                  value={credential.address}
                  onChange={(value) =>
                    dispatch(setCredentials({ ...credential, address: value }))
                  }
                  errorMessage={credentialsFormErrors.address}
                />
              </Grid>
              <Grid xs={12} md={2} padding={1}>
                <Input
                  type='text'
                  margin='normal'
                  name='addressNumber'
                  label='Número'
                  value={credential.addressNumber}
                  onChange={(value) =>
                    dispatch(
                      setCredentials({ ...credential, addressNumber: value }),
                    )
                  }
                  errorMessage={credentialsFormErrors.addressNumber}
                />
              </Grid>
              <Grid xs={12} md={2} padding={1}>
                <Input
                  type='text'
                  margin='normal'
                  name='addressComplement'
                  label='Complemento'
                  value={credential.addressComplement}
                  onChange={(value) =>
                    dispatch(
                      setCredentials({
                        ...credential,
                        addressComplement: value,
                      }),
                    )
                  }
                  errorMessage={credentialsFormErrors.addressComplement}
                />
              </Grid>
              <Grid xs={12} md={4} padding={1}>
                <Input
                  type='text'
                  margin='normal'
                  name='district'
                  label='Bairro'
                  value={credential.district}
                  onChange={(value) =>
                    dispatch(setCredentials({ ...credential, district: value }))
                  }
                  errorMessage={credentialsFormErrors.district}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper style={{ width: '100%', marginTop: 20, marginBottom: 15 }}>
            <Grid container padding={3}>
              <Typography variant='h5' color={palette.text.main}>
                Informações da credencial
              </Typography>
            </Grid>
            <Grid container>
              <Grid xs={12} md={6} padding={1}>
                <Input
                  type='text'
                  margin='normal'
                  label='Número'
                  value={credential.number.toString()}
                />
              </Grid>
              <Grid xs={12} md={6} padding={1}>
                <DatePicker
                  label='Data de validade'
                  value={moment(credential.validity).format('YYYY-MM-DD')}
                  onChange={(value) =>
                    dispatch(setCredentials({ ...credential, validity: value }))
                  }
                />
              </Grid>
              <Grid xs={12} padding={1}>
                <FileUpload
                  key={uploadFormKey}
                  onUpload={(form, file) => {
                    setFileForm(form);
                    setFile(file);
                    setIsFileUploadModalOpen(true);
                  }}
                />
                {credential.documents && (
                  <Grid
                    xs={12}
                    md={4}
                    paddingRight={responsiveMode == 'phone' ? 2 : 0}
                  >
                    <Button
                      fullWidth
                      color='primary'
                      variant='contained'
                      startIcon={<Download />}
                      label={`Download do arquivo: ${credential.documents}`}
                      onClick={handleDownloadDocument}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid xs={12} md={4} padding={1}>
                <Input
                  type='text'
                  margin='normal'
                  name='vehiclePlate'
                  label='Placa'
                  value={
                    credential.vehiclePlate
                      ? VMasker.toPattern(
                          credential.vehiclePlate,
                          'AAA9S99',
                        ).toUpperCase()
                      : ''
                  }
                  onChange={(value) => {
                    let handledValue = value;
                    if (handledValue.length > 7) {
                      handledValue = handledValue.slice(0, -1);
                    }
                    dispatch(
                      setCredentials({
                        ...credential,
                        vehiclePlate: handledValue,
                      }),
                    );
                  }}
                  errorMessage={credentialsFormErrors.vehiclePlate}
                />
              </Grid>
              <Grid xs={12} md={4} padding={1}>
                <Input
                  type='text'
                  margin='normal'
                  label='Marca'
                  value={credential.vehicleBrand}
                  onChange={(value) =>
                    dispatch(
                      setCredentials({ ...credential, vehicleBrand: value }),
                    )
                  }
                />
              </Grid>
              <Grid xs={12} md={4} padding={1}>
                <Input
                  type='text'
                  margin='normal'
                  label='Cor'
                  value={credential.vehicleColor}
                  onChange={(value) =>
                    dispatch(
                      setCredentials({ ...credential, vehicleColor: value }),
                    )
                  }
                />
              </Grid>
              <Grid xs={12} padding={1}>
                <Checkbox
                  label='Ativar delimitação'
                  checked={!!credential.delimited}
                  onChange={() => {
                    dispatch(
                      setCredentials({
                        ...credential,
                        delimited: credential.delimited == 0 ? 1 : 0,
                      }),
                    );
                  }}
                />
              </Grid>
              <Grid xs={12} padding={1}>
                <Input
                  type='text'
                  margin='normal'
                  label='Delimitação'
                  value={credential.observation}
                  onChange={(value) =>
                    dispatch(
                      setCredentials({ ...credential, observation: value }),
                    )
                  }
                />
              </Grid>
              <Grid xs={12} padding={1}>
                <Autocomplete
                  multiple
                  isOptionEqualToValue={(
                    selected: {
                      id: string;
                      value: string;
                    },
                    comparison: {
                      id: string;
                      value: string;
                    },
                  ) => {
                    return selected.id === comparison.id;
                  }}
                  options={routes.filter((route) => +route?.id != 0)}
                  getOptionLabel={(option) => option.value}
                  defaultValue={routes.filter((route) =>
                    credential.routes.includes(+route.id),
                  )}
                  value={routes.filter((route) =>
                    credential.routes.includes(+route.id),
                  )}
                  onChange={(_, value) => {
                    const routesId = value.map((value) => +value.id);
                    dispatch(
                      setCredentials({ ...credential, routes: routesId }),
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='filled'
                      label='Trecho(s) da delimitação'
                    />
                  )}
                />
              </Grid>
              <Grid container padding={1}>
                <Grid
                  xs={12}
                  md={location.pathname == CREDENTIALS_UPDATE ? 3 : 6}
                  padding={1}
                >
                  <Button
                    fullWidth
                    variant='contained'
                    label={
                      location.pathname == CREDENTIALS_CREATE
                        ? 'Cadastrar'
                        : 'Editar'
                    }
                    startIcon={<Save />}
                    onClick={handleSubmit}
                  />
                </Grid>
                {location.pathname == CREDENTIALS_UPDATE && (
                  <Grid xs={12} md={3} padding={1}>
                    <Button
                      fullWidth
                      color='error'
                      variant='contained'
                      label='Desativar'
                      startIcon={<Cancel />}
                      onClick={() => handleDeactivateCredential()}
                    />
                  </Grid>
                )}
                {location.pathname == CREDENTIALS_UPDATE && (
                  <Grid xs={12} md={3} padding={1}>
                    <Button
                      fullWidth
                      color='secondary'
                      variant='contained'
                      label='Renovar'
                      startIcon={<Loop />}
                      onClick={() => setIsRenewModalOpen(!isRenewModalOpen)}
                    />
                  </Grid>
                )}
                <Grid
                  xs={12}
                  md={location.pathname == CREDENTIALS_UPDATE ? 3 : 6}
                  padding={1}
                >
                  <Button
                    fullWidth
                    color='secondary'
                    variant='contained'
                    label='Voltar'
                    startIcon={<ArrowBack />}
                    onClick={() => navigate(CREDENTIALS_MANAGEMENT)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
      <DeactivateCredentialModal />
    </PageWrapper>
  );
};
