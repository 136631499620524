import DeleteIcon from '@mui/icons-material/Delete';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { Button as MuiButton } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, TableText } from '../../../components';
import { CONFIGURATION_UPDATE } from '../../../routes';
import {
  configurationSlice,
  configurationsSlice,
  fetchConfigurationInfo,
  useDispatch,
  useSelector,
} from '../../../stores';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { SavedConfigurationsTableToolbar } from './SavedConfigurationsTableToolbar';

export const SavedConfigurationsTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { configurationsList, configurationsListLoading, configurationsPage } =
    useSelector((state) => state.configurationsReducer);
  const responsiveMode = useResponsiveMode();
  const [rows, setRows] = useState<any[]>([]);
  const [filteredList, setFilteredList] = useState(configurationsList);

  useEffect(() => {
    if (configurationsList && configurationsList !== undefined) {
      setRows(configurationsList);
    } else setRows([]);
  }, [configurationsList]);

  const handleInfo = (configurationId: GridRowId) => {
    dispatch(
      fetchConfigurationInfo({ configurationId: configurationId.toString() }),
    );
    dispatch(configurationSlice.actions.toggleConfigurationInfoIsOpen());
  };

  const handleEdit = (configurationId: GridRowId) => {
    dispatch(
      fetchConfigurationInfo({ configurationId: configurationId.toString() }),
    ).then(() => {
      navigate(CONFIGURATION_UPDATE);
    });
  };

  const handleCancel = (configurationId: GridRowId) => {
    dispatch(
      fetchConfigurationInfo({ configurationId: configurationId.toString() }),
    );
    dispatch(configurationSlice.actions.toggleConfigurationCancelIsOpen());
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => <TableText label={params.row.name} size={30} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 305,
      renderCell: (params) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant='contained'
              label='Detalhar'
              startIcon={<InfoIcon />}
              size='small'
              onClick={() => handleInfo(params.id)}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            />
            <Button
              variant='contained'
              label='Editar'
              startIcon={<EditIcon />}
              size='small'
              onClick={() => handleEdit(params.id)}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            />
            <Button
              variant='contained'
              color='secondary'
              label='Excluir'
              startIcon={<DeleteIcon />}
              size='small'
              onClick={() => handleCancel(params.id)}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            />
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  const columnsResponsive: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => <TableText label={params.row.name} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: '',
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <MuiButton
              variant='contained'
              size='small'
              onClick={() => handleInfo(params.id)}
              sx={{
                minWidth: '30px',
              }}
            >
              <InfoTwoToneIcon />
            </MuiButton>
            <MuiButton
              variant='contained'
              size='small'
              onClick={() => handleEdit(params.id)}
              sx={{
                minWidth: '30px',
                marginLeft: '5px',
                marginRight: '5px',
              }}
            >
              <EditTwoToneIcon />
            </MuiButton>
            <MuiButton
              variant='contained'
              color='secondary'
              size='small'
              onClick={() => handleCancel(params.id)}
              sx={{
                minWidth: '30px',
              }}
            >
              <DeleteTwoToneIcon />
            </MuiButton>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  return (
    <Table
      paginationMode='client'
      page={configurationsPage}
      changePage={(value: number) =>
        dispatch(configurationsSlice.actions.setConfigurationsPage(value))
      }
      rows={rows}
      rowCount={filteredList ? filteredList.length : 0}
      columns={
        responsiveMode === 'phone' || responsiveMode === 'tablet'
          ? columnsResponsive
          : columns
      }
      toolbar={<SavedConfigurationsTableToolbar />}
      loading={configurationsListLoading}
    />
  );
};
